<template>
  <base-section
    id="theme-features"
    class="primary"
  >
    <base-section-heading title="Staking Design">
      The Icosa staking system is designed to compliment the HEX protocol as well as bring additional tokenomics and utility to the Hedron protocol.
    </base-section-heading>

    <v-container>
      <v-row>
        <v-col
          v-for="(feature, i) in features"
          :key="i"
          cols="12"
          md="6"
        >
          <base-avatar-card
            v-bind="feature"
            align="left"
            horizontal
            color="secondary"
          >
            {{ feature.content }}
          </base-avatar-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionThemeFeatures',

    data: () => ({
      features: [
        {
          title: 'One Stake Per Wallet',
          icon: 'mdi-numeric-1',
          content: 'Each wallet can hold only a single ICSA and HDRN stake. While HEX may be viewed as a blockchain certificate of deposit, Icosa is designed to be more of a blockchain savings account which you can add to over time.',
        },
        {
          title: 'Minimum Stake Lengths',
          icon: 'mdi-lock',
          content: 'Minimum stake lengths are enforced by the protocol, larger stakers are forced to stake for longer amounts of time. You can choose to end the stake early, but there will be penalty applied to your stake yield and principal.',
        },
        {
          title: 'Add Capital to Your Stake',
          icon: 'mdi-plus',
          content: 'Stakers can add capital to their stake at any time, as they see fit, in order to benefit from additional yield on following days. However, this will reset the minimum stake length for said stake if applicable. Those who choose not to add capital over time will be diluted by those that do.',
        },
        {
          title: 'No Maximum Stake Length',
          icon: 'mdi-all-inclusive',
          content: 'There is no maximum stake length for any stake within the Icosa protocol. This ensures those who wish to support the protocol long term are fully allowed to do so. Longer stakes do not cost more gas to end than shorter stakes.',
        },
      ],
    }),
  }
</script>
